.fade-in-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
}

/* Slide in from left */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Slide in from right */
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Slide in from top */
@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Slide in from bottom */
@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* General animation styles */
.slide-in-left {
  animation: slideInFromLeft 1s ease-out forwards;
}

.slide-in-right {
  animation: slideInFromRight 0.5s ease-out forwards;
}

.slide-in-top {
  animation: slideInFromTop 1s ease-out forwards;
}

.slide-in-bottom {
  animation: slideInFromBottom 1s ease-out forwards;
}

.hidden {
  opacity: 0;
}
.loader-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #0b0b0b;
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

#load {
  width: 100px;
  aspect-ratio: 4;
  --_g1: no-repeat
    radial-gradient(circle closest-side, #d4f36b 90%, transparent);
  --_g2: no-repeat
    radial-gradient(circle closest-side, #a47cf3 90%, transparent);
  --_g3: no-repeat
    radial-gradient(circle closest-side, #683fea 90%, transparent);
  background: var(--_g1) 0% 50%, var(--_g2) 50% 50%, var(--_g3) 100% 50%;
  background-size: calc(100% / 3) 100%;
  animation: l7 1s infinite linear;
}

@keyframes l7 {
  33% {
    background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%;
  }
  50% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%;
  }
  66% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%;
  }
}
