@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");
@font-face {
  font-family: "Sansation-Bold";
  src: url("../Image/fonts/Sansation_Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Sansation-Regular";
  src: url("../Image/fonts/Sansation_Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "OpenSauce-Semibold";
  src: url("../Image/fonts/OpenSauceOne-SemiBold.woff") format("woff");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
* {
  padding: 0;
  margin: 0;
  /* overflow-x: hidden; */
}
button {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
div {
  -webkit-tap-highlight-color: transparent;
}
.navBar {
  width: 100%;
  height: 67px;
  background-color: #eaeaec;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 64px;
  padding-right: 64px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  position: fixed;
  z-index: 100;
}
.hambug {
  cursor: pointer;
}
.topper {
  width: 100%;
  height: 67px;
}
.logo {
  display: flex;
  align-items: center;
}
.logo h3 {
  font-size: 22px;
  font-weight: 600;
  font-family: "OpenSauce-Semibold";
}
.logo img {
  width: 40px;
  height: 40px;
}
.secNav {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.secNav img {
  margin-right: 8px;
}
.apt {
  font-size: 16px;
  font-weight: 400;
  font-family: "Figtree", sans-serif;
}
.banner {
  width: 100%;
  height: 744px;
  background-image: url("../Image//banner.webp");
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bannerContent {
  position: absolute;
  bottom: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background: red; */
  width: 40%;
}
.bannerText {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  line-height: 75px;
  font-family: "Sansation-Bold";
  text-align: center;
}
.btn {
  width: 196px;
  height: 56px;
  border-radius: 100px;
  background-color: #a7fc00;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  font-family: "Figtree", sans-serif;
  border: none;
  margin-top: 40px;
}

.section1 {
  width: 100%;
  height: auto;
  padding-top: 160px;
}
.textSec {
  width: 45%;
  padding-left: 64px;
  padding-right: 64px;
}
.textSec h3 {
  font-family: "Sansation-Bold";
  font-size: 56px;
  margin-bottom: 16px;
}
.textSec p {
  font-weight: 400;
  font-family: "Figtree", sans-serif;
  font-size: 19px;
  line-height: 30px;
}
.CarouselCont {
  width: 100% !important;
  height: 620px;
  overflow-x: auto;
  margin-top: 79px;
  scroll-behavior: smooth;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.CarouselCont::-webkit-scrollbar {
  display: none;
}
.scroll {
  min-width: 100vw;
  height: 100%;
  padding-left: 64px;
  flex-direction: row;
  display: flex;
  /* padding-right: 20px; */
  /* background: red; */
  scroll-behavior: smooth;
}
.carouselCard {
  width: 390px;
  height: 100%;
  /* background: blue; */
  margin-right: 32px;
  border-radius: 32px;
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
  object-fit: cover;
  background-size: cover;
}
.cTextHead {
  margin-top: 28px;
}
.cTextHead h4 {
  font-family: "Sansation-Bold";
  font-size: 28px;
  line-height: 40px;
  color: #fff;
}
.plusBtn {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 30px;
  right: 24px;
}
.fpbtnCont {
  width: 100%;
  margin-top: 40px;
  padding-right: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
}

.fpBtn {
  cursor: pointer;
}
.fpBtnLeft {
  cursor: pointer;
  margin-right: 20px;
}

.brand {
  width: 100%;
  margin-top: 120px;
  padding-left: 64px;
  padding-right: 64px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.brandText {
  font-family: "Sansation-Bold";
  font-size: 56px;
}
.brandContent {
  width: 100%;
  height: 480px;
  background-color: #f5f5f7;
  border-radius: 32px;
  margin-top: 56px;
  padding-left: 72px;
  padding-right: 72px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.textSection {
  width: 40%;
}
.textH {
  font-family: "Sansation-Bold";
  font-size: 32px;
  line-height: 48px;
}
.textP {
  font-size: 18px;
  line-height: 30px;
  margin-top: 16px;
  font-weight: 400;
  font-family: "Figtree", sans-serif;
}

.btn2 {
  width: 196px;
  height: 56px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  position: relative;
  margin-top: 40px;
  background: none;
  font-weight: 500;
  font-size: 18px;
  font-family: "Figtree", sans-serif;
}
.btn2::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 100px;
  padding: 2px;
  background: linear-gradient(to right, #86ca00 0%, #ffb61d 58%, #683fea 100%)
    border-box;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.imgSection {
  width: 55%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.imgSection img {
  width: 46%;
  /* height: 70%; */
  object-fit: contain;
}
.imgSection img:last-child {
  width: 46%;
  /* height: 80%; */
}

.plan {
  width: 100%;
  margin-top: 160px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding-left: 64px;
  padding-right: 64px;
}

.planCentent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 56px;
}
.plan1 {
  width: 48%;
  height: 445px;
  border-radius: 32px;
  background-color: #f5f5f7;
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.plan1 h4 {
  margin-top: 24px;
  font-family: "Sansation-Bold";
  font-size: 32px;
  line-height: 44px;
}
.plan1 p {
  font-size: 18px;
  line-height: 30px;
  margin-top: 16px;
  font-weight: 400;
  font-family: "Figtree", sans-serif;
  width: 80%;
}
.plan1 img {
  width: 100%;
  height: 193px;
  margin-top: 32px;
}
.plan2 {
  width: 100%;
  margin-top: 160px;
}
.secA {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 64px;
  padding-right: 64px;
  /* background: red; */
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.aTag{
  color: #000 !important;
}
.secACont1 {
  width: 60%;
}
.secACont1 p {
  padding-right: 30%;
  margin-top: 16px;
  font-weight: 400;
  font-size: 19px;
  line-height: 30px;
  font-family: "Figtree", sans-serif;
}
.carouselCont2 {
  width: 100%; /* The full width of the carousel container */
  height: 400px;
  margin-top: 56px;
  position: relative;
  overflow: hidden; /* Ensures that only visible images are shown */
}
.slideCont{
  min-width: 100vw;
}
.slider {
  /* width: 300px; */
  padding-top: 5.6rem;
  /* margin: 0 8px; */
  padding-right: 8px;
  overflow: "hidden";
  /* padding:2rem 0; */
}

.slider img {
  width: 100%;
}

.react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
}

.custom-dot-list-style button {
  border: none;
  background: rgb(255, 68, 68);
}
.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  background: rgb(255, 68, 68) !important;
}

.faq {
  width: 100%;
  margin-top: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.faqText {
  font-size: 22px;
  font-weight: 400;
  font-family: "Figtree", sans-serif;
  margin-top: 16px;
  text-align: center;
}
.faqCont {
  width: 70%;
  margin-top: 56px;
}

.faqContent {
  width: 100%;
  /* padding-top: 27px;
  padding-bottom: 27px; */
  padding-bottom: 27px;
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: #f5f5f7;
  margin-bottom: 16px;
  border-radius: 16px;
  cursor: pointer;
}
.faqHeaderCont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: red; */
  padding-top: 27px;

  cursor: pointer;
}
.faqQuestion {
  font-size: 19px;
  font-weight: 500;
  font-family: "Figtree", sans-serif;
}
.faq-answer {
  color: #616161;
  width: 90%;
  font-size: 18px;
  line-height: 30px;
  margin-top: 15px;
  font-weight: 500;
  font-family: "Figtree", sans-serif;
}
.footer {
  margin-top: 160px;
  width: 100%;
  min-height: 656px;
  background-color: #000;
  padding-left: 80px;
  padding-right: 80px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.footerSection1 {
  width: 100%;
  padding-top: 64px;
  padding-bottom: 64px;
  border-bottom: 1px solid #4a4a4a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}
.fWrapper {
  width: 25%;
}
.footerHeader {
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  font-family: "Sansation-Bold";
}
.inputCont {
  width: 33vw;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* background: blue; */
  /* justify-content: space-between; */
}
.subscribe {
  width: 25%;
  height: 52px;
  position: relative;
  /* margin-left: 10px; */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Figtree", sans-serif;
  font-weight: 500;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}
.subscribe::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 100px;
  padding: 1px;
  background: linear-gradient(to right, #86ca00 0%, #ffb61d 58%, #683fea 100%)
    border-box;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.input {
  width: 73%;
  height: 52px;
  border-radius: 100px;
  background-color: #1d1d1d;
  border: none;
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-size: 15px;
  color: #fff;
  outline-color: #4a4a4a !important;
  font-family: "Figtree", sans-serif;
  font-weight: 400;
}
.input:focus {
  outline: 1px solid #4a4a4a; /* Add custom outline on focus */
  border-radius: 100px;
  outline-offset: 1px !important;
}
.input::placeholder {
  color: #838383;
}
.footerSection2 {
  width: 100%;
  min-height: 324px;
  border-bottom: 1px solid #4a4a4a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}
.content1 {
  width: 50%;
  /* height: 194px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content1 img {
  width: 40px;
  height: 40px;
  margin-bottom: 16px;
}
.content1 h4 {
  font-size: 24px;
  color: #fff;
  font-family: "Figtree", sans-serif;
  font-weight: 600;
}
.content1 p {
  margin-top: 8px;
  color: #b3b4b3;
  font-family: "Figtree", sans-serif;
  font-weight: 400;
}
.fContentWrapper {
  width: 50%;
  display: flex;
  flex-wrap: wrap;

  /* align-items: center; */
}
.content2 {
  width: 30%;
  /* height: 196px; */
  /* background: red; */
}
.ph {
  font-size: 16px;
  font-family: "Sansation-Regular";
  color: #b3b4b3;
  margin-bottom: 24px;
}
.pp {
  font-size: 15px;
  color: #fff;
  margin-bottom: 16px;
  font-family: "Figtree", sans-serif;
  font-weight: 400;
}
.pp a {
  text-decoration: none;
  font-family: "Figtree", sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
}
.content3 {
  width: 30%;
  /* height: 196px; */
  /* background: blue; */
}
.content4 {
  width: 40%;
  /* height: 196px; */
}
.fBtn {
  width: 244px;
  height: 52px;
  margin-top: 30px;
  font-size: 18px;
  border-radius: 100px;
  cursor: pointer;
}
.pp1 {
  margin-bottom: 40px;
}
.social {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.social img {
  margin-right: 16px;
}
.lastFooterSec {
  display: flex;
  padding-top: 40px;
  position: relative;
  justify-content: space-between;
}
.localWrap {
  width: 80%;
  /* background: red; */
  display: flex;
  /* justify-content: space-between; */
}
.ngLocation {
  margin-right: 48px;
}
.address {
  font-size: 14px;
  color: #b3b4b3;
  font-family: "Figtree", sans-serif;
  font-weight: 400;
}
.country {
  color: #fff;
  font-size: 15px;
  margin-bottom: 8px;
  font-family: "Figtree", sans-serif;
  font-weight: 400;
}
.copyright {
  /* position: absolute;
  right: 0; */
  color: #fff;
  font-size: 14px;
  font-family: "Figtree", sans-serif;
}
.emoji {
  font-family: "Apple Color Emoji", "Segoe UI Emoji", sans-serif;
}

/* menu */

.menuBody {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 2000;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 1s ease-out, transform 1s ease-out;
}
.opacity {
  opacity: 1;
}
.cBtn {
  background: none;
  border: none;
  position: absolute;
  top: 32px;
  right: 64px;
  cursor: pointer;
}
.links {
  font-size: 52px;
  line-height: 72px;
  color: #000;
  font-family: "Sansation-Bold";
  text-align: center;
  margin-bottom: 64px;
  cursor: pointer;
}
.menuWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.socials {
  position: absolute !important;
  bottom: 64px;
}
.socials img{
  margin-right: 0 !important;
}
.socials a {
  margin-right: 40px;
}
.socials a:last-child {
  margin-right: 0px;
}
.links:hover{
color: #683FEA;
}
.popBody {
  width: 100%;
  height: 100vh;
  background: #00000066;
  backdrop-filter: blur(10px);
  position: fixed;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popCard {
  width: 90%;
  min-height: 70%;
  background: #fff;
  border-radius: 32px;
  padding-left: 64px;
  padding-right: 64px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding-top: 48px;
  padding-bottom: 48px;
}
.pnav {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* position: relative; */
}
.logoSec p {
  font-size: 18px;
  font-family: "Figtree", sans-serif;
  font-weight: 500;
}
.logoSec h4 {
  font-size: 44px;
  line-height: 40px;
  font-family: "Sansation-Bold";
  margin-top: 12px;
}
.closeBtn {
  background: none;
  border: none;
  cursor: pointer;
  /* position: absolute; */
  /* right: 32px; */
}
.pBody {
  width: 100%;
  height: 50vh;
  margin-top: 48px;
  background: #f5f5f7;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.p2Body {
  width: 100%;
  height: 55vh;
  margin-top: 48px;
  background: #f5f5f7;
  border-radius: 32px;
  display: flex;
  justify-content: space-between;
  padding-left: 52px;
  padding-right: 52px;
  padding-top: 36px;
  padding-bottom: 36px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.textCont {
  max-width: 453px;
}
.textCont p {
  font-size: 18px;
  line-height: 28px;
  color: #4a4a4a;
  font-family: "Figtree", sans-serif;
  font-weight: 500;
}
.textCont p span {
  color: #000;
  font-weight: 600;
}
.pBody p {
  padding-left: 52px;
  padding-right: 52px;
  padding-top: 36px;
  padding-bottom: 56px;
  font-size: 18px;
  line-height: 28px;
  color: #4a4a4a;
  font-family: "Figtree", sans-serif;
  font-weight: 500;
}
.pBody p span {
  color: #000;
  font-weight: 600;
}
.imgs {
  display: flex;
  flex-direction: row;
  /* background: red; */
  justify-content: space-between;
}
.imgs img {
  width: 24.5%;
}
.imgs img:first-child {
  border-bottom-left-radius: 32px;
}
.imgs img:last-child {
  border-bottom-right-radius: 32px;
}
.imgCont {
  height: 100%;
  width: 50%;
}
.imgCont img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 32px;
}
.cCont {
  width: 100%;
  height: 40%;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  border-radius: 24px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.con {
  height: 100%;
  width: 100%;
  margin: 0;
}
.slideImg {
  width: auto;
  height: 200px;
  background: gold;
  margin-right: 5px;
  /* position: absolute; */
}
.slideImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* terms of use */

.tCont {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-self: center !important;
}
.tItemHead {
  width: 80%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 230px;
  border-bottom: 1px solid #b3b4b3;
}
.tH {
  font-size: 52px;
  font-family: "Sansation-Bold";
}
.tItemHead p {
  font-size: 18px;
  line-height: 30px;
  margin-top: 13px;
  font-family: "Figtree", sans-serif;
  font-weight: 400;
}
.tItemBody {
  width: 80% !important;
  /* background: red; */
}
.item {
  margin-top: 40px;
}
.item h4 {
  font-size: 26px;
  line-height: 30px;
  font-family: "Sansation-Bold";
}
.item p {
  font-size: 18px;
  line-height: 30px;
  margin-top: 16px;
  font-family: "Figtree", sans-serif;
  font-weight: 400;
}

.errorText{
  font-size: 14px;
  font-family: "Figtree", sans-serif;
  font-weight: 400;
  color: #BA2543;
  margin-top: 12px;
  margin-left: 3px;
}
.successText{
  font-size: 14px;
  font-family: "Figtree", sans-serif;
  font-weight: 400;
  color: #A7FC00;
  margin-top: 12px;
  margin-left: 3px;
}
.indi{
  width: 20%;
}
/* .popSlide{
  padding-left: 10px;
} */



.marquee {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 56px;
}

.marquee-content {
  display: flex;
  animation: marquee 25s linear infinite; /* The speed and direction of the scrolling */
}

.marquee-item {
  min-width: 300px; /* Adjust width as needed */
  margin-right: 8px;
  min-height: 400px;
}

.marquee-item img {
  width: 100%;
  height: 100;
  object-fit: cover;
}

/* Define the marquee animation */
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.marquee {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.marquee-content {
  display: flex;
  transition: transform 1s linear; /* Smooth transition */
}

.marquee-content.left {
  animation: marquee-left 20s linear infinite;
}

.marquee-content.right {
  animation: marquee-right 20s linear infinite;
}

.marquee-item {
  min-width: 300px; /* Adjust width as needed */
  margin-right: 10px;
  height: 400px;
}

.marquee-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Define the marquee animation for scrolling left */
@keyframes marquee-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Define the marquee animation for scrolling right */
@keyframes marquee-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
