@media screen and (max-width: 767px) {
  .navBar {
    height: 53px;
    padding-left: 17.5px;
    padding-right: 17.5px;
  }
  .topper{
    height: 53px !important;
  }
  .logo h3 {
    font-size: 17px;
  }
  .logo img {
    width: 24px;
    height: 24px;
  }
  .hambug img{
    width: 18px;
    height: 10px;
  }
  .secNav {
    width: 100%;
    height: 44px;
    align-items: center;
    justify-content: center;
  }
  .secNav img{
    width: 20px;
    height: 20px;
  }
  .apt {
    font-size: 14px;
  }
  .secNav img {
    margin-right: 6px;
  }
  .banner {
    width: 100%;
    height: 673px;
    background-image: url("../Image/mBanner.webp");
  }
  .bannerContent {
    position: absolute;
    bottom: 56px;
    width: 80%;
  }
  .bannerText {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
    line-height: 40px;
  }
  .btn {
    width: 180px;
    height: 54px;
    border-radius: 100px;
    margin-top: 30px;
    font-size: 16px;
  }
  .section1 {
    width: 100%;
    height: auto;
    padding-top: 88px;
  }
  .textSec {
    width: 80%;
    padding-left: 24px;
    padding-right: 24px;
  }
  .textSec h3 {
    font-size: 32px;
    margin-bottom: 14px;
  }
  .textSec p {
    font-size: 16px;
    line-height: 24px;
  }
  .CarouselCont {
    height: 490px;
    margin-top: 32px;
  }
  .CarouselCont::-webkit-scrollbar {
    display: none;
  }
  .scroll {
    min-width: 1200px;
    height: 100%;
    padding-left: 24px;
  }
  .carouselCard {
    width: 280px !important;
    height: 100%;
    margin-right: 16px;
    border-radius: 22px;
    padding-left: 24px;
    padding-right: 24px;
    position: relative;
    object-fit: cover;
    background-size: cover;
  }
  .cTextHead {
    margin-top: 24px;
  }
  .cTextHead h4 {
    font-size: 22px;
    line-height: 30px;
  }
  .plusBtn {
    bottom: 16px;
    right: 16px;
  }
  .plusBtn img {
    width: 38px;
    height: 38px;
  }
  .fpbtnCont {
    margin-top: 30px;
    padding-right: 24px;
  }
  .fpBtn svg {
    width: 38px;
    height: 38px;
  }
  .fpBtnLeft {
    margin-right: 15px;
  }
  .fpBtnLeft svg {
    width: 38px;
    height: 38px;
  }
  .brand {
    margin-top: 72px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .brandText {
    font-size: 32px;
  }
  .brandContent {
    width: 100%;
    height: 553px;
    margin-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    flex-direction: column;
  }
  .textSection {
    width: 100%;
    margin-top: 32px;
  }
  .textH {
    font-size: 24px;
    line-height: 34px;
  }
  .textP {
    font-size: 16px;
    line-height: 26px;
    margin-top: 16px;
  }
  .btn2 {
    width: 154px;
    height: 52px;
    margin-top: 30px;
    font-size: 15px;
  }
  .imgSection {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .imgSection img {
    width: 46%;
    object-fit: contain;
  }
  .imgSection img:last-child {
    width: 48%;
    /* height: 90%; */
  }
  .plan {
    margin-top: 100px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .planCentent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
  }
  .plan1 {
    width: 100%;
    height: 352px;
    border-radius: 22px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .plan1:first-child {
    margin-bottom: 24px;
  }
  .plan1 h4 {
    margin-top: 30px;
    font-size: 24px;
    line-height: 36px;
  }
  .plan1 p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
    width: 90%;
  }
  .plan1 img {
    width: 100%;
    height: 120px;
    margin-top: 24px;
    border-radius: 12px;
  }
  .plan2 {
    width: 100%;
    margin-top: 100px;
  }
  .secA {
    padding-left: 24px;
    padding-right: 24px;
    flex-direction: column;
    align-items: flex-start;
  }
  .secACont1 {
    width: 100%;
  }
  .secACont1 p {
    padding-right: 10%;
    margin-top: 16;
  }
  .fBtn {
    width: 202px;
    font-size: 15px;
  }
  .slider {
    padding-top: 48px;
    margin-top: 48px;
    margin: 0 0px;
    height: 350px;
    padding-right: 6px;
  }
  .slider img {
    /* width: 100%;
    height: 100%; */
  }

  .faq {
    width: 100%;
    margin-top: 100px;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .faqText {
    font-size: 16px;
    margin-top: 10px;
  }
  .faqCont {
    width: 100%;
    margin-top: 32px;
  }
  .faqContent {
    padding-bottom: 22px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .faqHeaderCont {
    padding-top: 22px;
  }
  .faqQuestion {
    font-size: 16px;
  }
 .icon{
  width: 24px;
  height: 24px;
 }
  .faq-answer {
    margin-top: 10px;
    font-size: 15px;
    line-height: 24px;
  }
  .footer {
    margin-top: 88px;
    width: 100%;
    min-height: 656px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .footerSection1 {
    width: 100%;
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .fWrapper {
    width: 90%;
  }
  .footerHeader {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 32px;
  }
  .inputCont{
    width: 88vw !important;
    /* background: red; */
  }
  .input {
    width: 70%;
    height: 50px;
    border-radius: 100px;
    font-size: 15px;
  }
  .secAp{
    line-height: 24px !important;
    font-size: 16px !important;
  }
  .subscribe{
    width: 28%;
  }
  .footerSection2 {
    flex-direction: column;
    align-items: flex-start;
  }
  .content1 {
    margin-top: 48px;
  }
  .content1 h4 {
    font-size: 20px;
  }
  .content1 p {
    font-size: 15px;
  }
  .fBtn2 {
    width: 160px !important;
    height: 48px !important;
    margin-top: 26px !important;
  }
  .fContentWrapper {
    width: 100%;
    margin-bottom: 40px;
  }
  .content2,
  .content3,
  .content4 {
    margin-top: 32px;
    width: 50%;
  }
  .lastFooterSec {
    flex-direction: column;
    padding-bottom: 24px;
  }
  .localWrap {
    width: 100%;
    flex-direction: column;
  }
  .ngLocation {
    margin-bottom: 24px;
  }

  /* menu */
  .menuWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 17vh;
    height: 100vh;
  }
  .links {
    font-size: 32px;
    margin-bottom: 12px;
  }
  .cBtn {
    top: 40px;
    right: 24px;
    cursor: pointer;
  }
  .socials {
    position: absolute;
    bottom: 10vh;
  }
  .popCard {
    width: 100vw;
    min-height: 90vh;
    position: absolute;
    bottom: 0;
    padding-left: 24px;
    padding-right: 24px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    overflow-y: auto;
  }
  .logoSec p {
    font-size: 18px;
    font-family: "Figtree", sans-serif;
    font-weight: 500;
  }
  .logoSec h4 {
    font-size: 38px;
    line-height: 40px;
    font-family: "Sansation-Bold";
    margin-top: 12px;
  }
  .closeBtn img {
    width: 32px;
    height: 32px;
  }
  .pBody {
    min-height: 70vh;
    margin-top: 36px;
    position: relative;
    border-radius: 24px;
  }
  .pBody p {
    padding-left: 24px;
    padding-left: 24px;
    padding-top: 24px;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 40px;
    /* background: red; */
  }
  .imgs {
    height: 200px;
    background: red;
  }
  .p2Body {
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    min-height: 65vh;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .textCont p {
    font-size: 16px;
    line-height: 24px;
  }
  .imgCont {
    height: 45%;
    width: 100%;
  }
  .imgCont img {
    border-radius: 16px;
  }

  .tItemHead {
    width: 85%;
    align-items: flex-start;
    height: 123px;
  }
  .tItemBody {
    width: 85% !important;
  }
  .item{
    margin-top: 30px;
  }
  .tH {
    font-size: 30px;
  }
  .tItemHead p {
    font-size: 16px;
    margin-top: 10px;
  }
  .item h4 {
    font-size: 24px;
  }
  .item p {
    font-size: 16px;
    line-height: 26px;
  }
  .collab{

  }
  .socials a {
    margin-right: 32px;
  }
}

@media screen and (max-width: 1250px) and (min-width: 767px) {
  .navBar {
    height: 60px;
    padding-left: 48px;
    padding-right: 48px;
  }
  .logo h3 {
    font-size: 17px;
  }
  .logo img {
    width: 30px;
    height: 30px;
  }
  .secNav {
    width: 100%;
    height: 48px;
  }
  .apt {
    font-size: 14px;
  }
  .secNav img {
    margin-right: 6px;
  }
  .banner {
    width: 100%;
    height: 1232px;
    background-image: url("../Image/tBanner.webp");
  }
  .bannerContent {
    position: absolute;
    bottom: 156px;
    width: 60%;
  }
  .bannerText {
    font-size: 54px;
    font-weight: 700;
    color: #fff;
    line-height: 64px;
  }
  .btn {
    width: 180px;
    height: 54px;
    border-radius: 100px;
    margin-top: 48px;
    font-size: 14px;
  }

  .section1 {
    width: 100%;
    height: auto;
    padding-top: 140px;
  }
  .textSec {
    width: 50%;
    padding-left: 48px;
    padding-right: 48px;
  }
  .textSec h3 {
    font-size: 50px;
    margin-bottom: 14px;
  }
  .textSec p {
    font-size: 17px;
  }
  .CarouselCont {
    height: 620px;
    margin-top: 32px;
  }
  .CarouselCont::-webkit-scrollbar {
    display: none;
  }
  .scroll {
    min-width: 800px;
    height: 100%;
    padding-left: 48px;
    padding-right: 48px;
  }
  .carouselCard {
    width: 330px !important;
    height: 100%;
    margin-right: 32px;
    border-radius: 22px;
    padding-left: 32px;
    padding-right: 32px;
    position: relative;
    object-fit: cover;
    background-size: cover;
    flex-shrink: 0;
  }
  .cTextHead {
    margin-top: 28px;
  }
  .cTextHead h4 {
    font-size: 26px;
    line-height: 37px;
  }
  .plusBtn {
    bottom: 30px;
    right: 24px;
  }
  .plusBtn img {
    width: 38px;
    height: 38px;
  }
  .fpbtnCont {
    margin-top: 32px;
    padding-right: 48px;
  }
  .fpBtn svg {
    width: 44px;
    height: 44px;
  }
  .fpBtnLeft {
    margin-right: 15px;
  }
  .fpBtnLeft svg {
    width: 44px;
    height: 44px;
  }

  .brand {
    margin-top: 176px;
    padding-left: 48px;
    padding-right: 48px;
  }
  .brandText {
    font-size: 50px;
  }
  .brandContent {
    width: 100%;
    height: 426px;
    margin-top: 40px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .textSection {
    width: 45%;
    margin-top: 60px;
  }
  .textH {
    font-size: 32px;
    line-height: 38px;
  }
  .textP {
    font-size: 16px;
    line-height: 26px;
    margin-top: 16px;
  }
  .btn2 {
    width: 180px;
    height: 52px;
    margin-top: 30px;
    font-size: 16px;
  }
  .imgSection {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .imgSection img {
    width: 46%;
    height: none;
    object-fit: contain;
  }
  .imgSection img:last-child {
    width: 48%;
    /* height: 90%; */
  }

  .plan {
    margin-top: 140px;
    padding-left: 48px;
    padding-right: 48px;
  }
  .planCentent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
  }
  .plan1 {
    width: 48%;
    min-height: 413px;
    border-radius: 22px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .plan1 h4 {
    margin-top: 32px;
    font-size: 30px;
    line-height: 40px;
  }
  .plan1 p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
    width: 100%;
  }
  .plan1 img {
    width: 100%;
    height: 177px;
    margin-top: 32px;
    border-radius: 16px;
  }
  .plan2 {
    width: 100%;
    margin-top: 140px;
  }
  .secA {
    padding-left: 48px;
    padding-right: 48px;
    /* flex-direction: column;
    align-items: flex-start; */
  }
  .secACont1 {
    width: 70% !important;
    /* background: red; */
  }
  .secACont1 p {
    padding-right: 10%;
    margin-top: 16;
  }
  .fBtn {
    width: 213px;
    font-size: 16px;
  }
  .slider {
    padding-top: 48px;
    margin-top: 40px;
    margin: 0 0px;
    height: 350px;
    padding-right: 6px;
  }
  .slider img {
    /* width: 100%;
    height: 100%; */
  }

  .faq {
    width: 100%;
    margin-top: 140px;
    padding-left: 48px;
    padding-right: 48px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .faqText {
    font-size: 20px;
    margin-top: 14px;
  }
  .faqCont {
    width: 90%;
    margin-top: 48px;
  }
  .faqContent {
    padding-bottom: 23px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .faqHeaderCont {
    padding-top: 23px;
  }
  .faqQuestion {
    font-size: 17px;
  }
  .faq-answer {
    margin-top: 15px;
    font-size: 16px;
    line-height: 28px;
  }
  .footer {
    margin-top: 140px;
    width: 100%;
    min-height: 588px;
    padding-left: 48px;
    padding-right: 48px;
  }
  .fWrapper {
    width: 30%;
  }
  .footerHeader {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 16px;
  }
  .subscribe {
    width: 100px;
    height: 48px;
  }

  .content1 {
    width: 35%;
    /* background: red; */
    margin-top: 0;
  }
  .content1 h4 {
    font-size: 20px;
  }
  .content1 p {
    font-size: 15px;
  }
  .fBtn2 {
    width: 160px !important;
    height: 48px !important;
    margin-top: 26px !important;
  }
  .fContentWrapper {
    width: 60%;
    /* background: blue; */
    justify-content: space-between;
  }
  .content2 {
    width: 30%;
  }
  .content3 {
    width: 30%;
  }
  .content4 {
    width: 40%;
  }
  .ph {
    font-size: 15px !important;
  }
  .pp a {
    font-size: 14px !important;
  }
  .lastFooterSec {
    padding-bottom: 32px;
  }
  .localWrap {
    width: 80%;
    /* justify-content: space-between; */
  }
  .ngLocation {
    margin-right: 32px;
  }
  .copyright {
    position: absolute;
    right: 0;
    color: #fff;
    font-size: 12px;
    font-family: "Figtree", sans-serif;
  }
  .address {
    font-size: 12px;
  }
  .menuWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20vh;
    height: 100vh;
  }
  .links {
    font-size: 40px;
    margin-bottom: 12px;
  }
  .cBtn {
    top: 40px;
    right: 24px;
    cursor: pointer;
  }
  .cBtn img {
    width: 42px;
    height: 42px;
  }
  .socials {
    position: absolute;
    bottom: 10vh;
  }
  /*  */
  .closeBtn img {
    width: 40px;
    height: 40px;
  }
  .popCard {
    padding-left: 50px;
    padding-right: 50px;
  }
  .pBody p {
    padding-left: 40px;
    padding-right: 40px;
    font-size: 17px;
    line-height: 28px;
    padding-bottom: 0;
  }
  .imgs img {
    width: 24.5%;
    height: 18vh;
    object-fit: cover;
  }
  .logoSec h4 {
    font-size: 40px;
  }
  .textCont {
    max-width: 453px;
    width: 45%;
  }
  .textCont p {
    font-size: 17px;
    line-height: 27px;
  }
  .p2Body {
    padding-left: 32px;
    padding-right: 32px;
  }
  .imgCont {
    width: 45%;
  }
  .tItemHead {
    width: 85%;
    height: 186px;
  }
  .tH {
    font-size: 52px;
  }
  .tItemBody{
    width: 85% !important;
  }
  .tItemHead p {
    font-size: 16px;
    margin-top: 10px;
  }
  .item h4 {
    font-size: 26px;
  }
  .item{
    margin-top: 30px;
  }
}
